var raw = `
You don't fail until you stop trying.
- Albert Einstein

I failed my way to success.
- Thomas Edison

Only those who dare to fail greatly can ever achieve greatly.
- John F. Kennedy

It is hard to fail, but it is worse never to have tried to succeed.
- Theodore Roosevelt

The future belongs to those who believe in the beauty of their dreams.
- Eleanor Roosevelt

I avoid looking forward or backward, and try to keep looking upward.
- Charlotte Bronte

Every artist was first an amateur.
- Ralph Waldo Emerson

Our business in life is not to get ahead of others, but to get ahead of ourselves.
- E. Joseph Cossman

Insist on yourself. Never imitate.
- Ralph Waldo Emerson

Who looks outside, dreams. Who looks inside, awakes.
- Carl Jung

The only journey is the one within.
- Rainer Maria Rilke

Happiness is not a goal, but a by-product.
- Eleanor Roosevelt

Happiness is not a state to arrive at, but a manner of traveling.
- Margaret Lee Runbeck

Purpose is what gives life a meaning.
- C. H. Parkhurst

Life can be pulled by goals just as surely as it can be pushed by drives.
- Viktor Frankl

Success is the child of audacity.
- Benjamin Disraeli

A goal is a dream with a deadline.
- Napoleon Hill

The best way out is always through.
- Robert Frost

You miss 100 percent of the shots you don't take.
- Wayne Gretzky

Success is how high you bounce after you hit bottom.
- George Patton

Fall seven times, stand up eight.
- Japanese Proverb

What is not started will never get finished.
- Johann Wolfgang von Goethe

Being realistic is the most commonly traveled road to mediocrity.
- Will Smith

The road to success is always under construction.
- Arnold Palmer

Whether you think you can or you can’t, you’re right.
- Henry Ford

One day your life will flash before your eyes. Make sure it is worth watching.
- Mooie

Do or do not. There is no try.
- Yoda
`;

// parse quotes from raw text
var quotes = raw.split('\n\n').map(block => {
   var matches = (block + '').match(/(.+)^-(.+)/ms);
   return {
      text: matches[1].trim(),
      author: matches[2].trim()
   }
});

export default quotes;