<template>
  <f7-page name="home">
    <wuju-navbar :menu="true" :back="false" title="Wuju" subtitle="be gentle with yourself"></wuju-navbar>
    <wuju-help-sheet page="home"></wuju-help-sheet>
    <f7-toolbar tabbar position="bottom">
      <f7-link tab-link="#tab-thoughts" :tab-link-active="$storage.settings.tab === 'thoughts'">Thoughts</f7-link>
      <f7-link tab-link="#tab-feelings" :tab-link-active="$storage.settings.tab === 'feelings'">Feelings</f7-link>
      <f7-link tab-link="#tab-guide">Guide</f7-link>
      <!-- <f7-link tab-link="#tab-sensations">Sensations</f7-link> -->
    </f7-toolbar>
    <f7-block ref="quoteBlock">
      <wuju-quote :quotes="quotes"></wuju-quote>
      <f7-block v-if="progress.progress() <= 100">
          <wuju-progress :progress="progress.progress()" :label="`${progress.message()}`"></wuju-progress>
          <f7-button v-if="nextLesson" href="/lessons" class="small no-margin">Up Next: {{nextLesson.title}}</f7-button>
      </f7-block>
      <wuju-celebrate v-if="progress.progress() == 100" :autostart="500"></wuju-celebrate>
    </f7-block>
    <f7-tabs>
      <f7-tab id="tab-thoughts" :tab-active="$storage.settings.tab === 'thoughts'"  v-on:tab:show="$storage.settings.tab = 'thoughts'">
        <f7-list>
          <f7-list-group class="thought-group" v-for="(category, key) in thoughts" :key="key">
            <f7-list-item :title="category.title" group-title></f7-list-item>
            <f7-list-item
              v-for="(thought, index) in category.thoughts"
              :key="index"
              :title="thought"
              link="#"
              @click="gotoThought(key, index)"
            >
            </f7-list-item>
          </f7-list-group>
        </f7-list>
      </f7-tab>
      <f7-tab id="tab-feelings" :tab-active="$storage.settings.tab === 'feelings'" v-on:tab:show="$storage.settings.tab = 'feelings'">
        <wuju-feeling-list categorize show-other :highlight="highlight" :exiturl="exiturl"></wuju-feeling-list>
      </f7-tab>
      <f7-tab id="tab-guide" v-on:tab:show="hideQuote()" v-on:tab:hide="showQuote()">
        <f7-block>
          <f7-card class="theme-background">
            <f7-card-content class="content">
              <p class="large center">Guide a friend</p>
            </f7-card-content>
          </f7-card>
          <f7-block>
            <p>Wuju can be a little intimidating the first time around.<br>Helping a friend through their first session could make a huge difference.</p>
            <p>Share the link below with them and you'll be able to see their process in real time.</p>

            <p class="copy-link" v-on:click="copyLink(shareLink(true))">{{ shareLink(true) }}</p>
            <wuju-share :url="shareLink(true)" :include="['messenger', 'whatsapp', 'sms']"></wuju-share>
            <br>
            <f7-button round fill :href="viewLink(false)">View their session</f7-button>
            <f7-button class="small" @click="shareSession()">or share yours</f7-button>
          </f7-block>
        </f7-block>
      </f7-tab>
      <!-- <f7-tab id="tab-sensations">
        <f7-list>
          <f7-list-group class="sensation-group" v-for="(category, key) in sensations" :key="key">
            <f7-list-item :title="category.title" group-title></f7-list-item>
            <f7-list-item
              v-for="(sensation, index) in category.sensations"
              :key="index"
              :title="sensation"
              :link="`/sensations/${key}/${index}`"
            >
            </f7-list-item>
          </f7-list-group>
        </f7-list>
      </f7-tab> -->
    </f7-tabs>
  </f7-page>
</template>

<style scoped>
  .video-thumb {
    width: 50px;
    float: right;
    margin: 0 0 10px 10px;
  }
  .copy-link {
    font-size: 120%;
    text-align: center;
    border: 1px solid #eee;
    border-radius: 2px;
    padding: 5px 10px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>

<script>
import Utils from '../js/utils.js';
import Thoughts from '../js/thoughts.js';
import Sensations from '../js/sensations.js';
import Progress from '../js/progress.js';
import Quotes from '../js/quotes.js';
import Lessons from '../js/lessons.js';
import Narration from '../js/narration.js';
import Record from '../js/record.js';

export default {
  props: {
    install: {
      type: Boolean,
      default: true,
    },
    shared: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Array,
      default: () => [],
    },
    play: {
      type: String,
      default: null
    },
    exiturl: {
      type: String,
      default: null
    }
  },
  data: function() {
    return {
      thoughts: Thoughts,
      sensations: Sensations,
      quotes: Quotes,
    }
  },
  methods: {
    showInstallTip() {
      if (! this.install) return;
      if (this.shared || this.$store.shared) return;

      if (this.$f7.device.standalone) return;
      if (! this.$f7.device.ios && ! this.$f7.device.android) return;

      var androidText = `
        To install Wuju go to your browser menu (<i class="icon material-icons" style="font-size: 18px; line-height: 0.9">more_vert</i>) and click <em>Install app</em>.
      `;

      var iosText = `
        To install Wuju
        tap <i class="icon f7-icons" style="font-size: 18px; line-height: 0.9">square_arrow_up</i>
        and then <em>Add to Home Screen</em>
      `;

      var toast = this.$f7.toast.create({
        text: this.$f7.device.ios ? iosText : androidText,
        closeButton: true,
        closeTimeout: 10000,
      });

      setTimeout(() => {
        toast.open();
      }, 2000);
    },
    gotoThought(key, index) {
      this.$f7router.navigate(`/thoughts/${key}/${index}`, { props: { exiturl: this.exiturl } });
    },
    showQuote() {
      this.$$(this.$refs.quoteBlock.$el).show();
    },
    hideQuote() {
      this.$$(this.$refs.quoteBlock.$el).hide();
    },
    shareLink(external) {
      return (external ? window.location.origin + '/#!' : '') + '/share/' + Record.groupId();
    },
    viewLink(external) {
      return (external ? window.location.origin + '/#!' : '') + '/view/' + Record.groupId();
    },
    copyLink(link) {
      this.$clipboard(link);
      this.$f7.toast.show({
        text: 'Share link copied to clipboard!',
        closeTimeout: 4000,
      });
    },
    shareSession() {
      this.copyLink(this.viewLink(true));
      this.$f7router.navigate(this.shareLink(), { clearPreviousHistory: true });
    },
    ...Utils,
  },
  computed: {
    progress() {
      return Progress;
    },
    lessons() {
      return Lessons;
    },
    nextLesson() {
      for (var lesson of Object.values(Lessons)) {
        if (! this.$storage.lessons.get(lesson.key)) return lesson;
      }
    },
  },
  mounted() {
    Utils.clearObject(this.$store.intensity);
    this.showInstallTip();
    if (this.play) {
      this.$nextTick(() => {
        Narration.play(this.play);
      });
    }
    if (this.shared) {
      this.$store.shared = true;
      Record.record(this.$f7route.params.group);
    }
  },
}
</script>