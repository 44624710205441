<template>
  <f7-page name="lessons">
    <wuju-navbar :menu="true" :back="true" title="Wuju" subtitle="be gentle with yourself"></wuju-navbar>
    <f7-block>
      <wuju-quote :quotes="quotes"></wuju-quote>
    </f7-block>
    <f7-list>
      <f7-list-item title="Emotional mastery" group-title></f7-list-item>
      <f7-list-item
        v-for="(lesson, key) in lessons"
        :key="key"
        :title="lesson.title + (lesson.tbd ? ' - TBD' :'')"
        :footer="lesson.subtitle"
        link="#"
        @click="goto(key)"
        :class="lesson.tbd ? 'disabled' : ''"
      >
        <f7-icon style="margin-right: 10px" :f7="$storage.lessons.get(key) ? 'checkmark_alt' : ''" slot="after" color="theme"></f7-icon>
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<style scoped>
  .video-thumb {
    width: 50px;
    float: right;
    margin: 0 0 10px 10px;
  }
</style>

<script>
import Utils from '../js/utils.js';
import Music from '../js/music.js';
import Lessons from '../js/lessons.js';
import Quotes from '../js/quotes.js';

export default {
  data: function() {
    return {
      lessons: Lessons,
      quotes: Quotes,
    }
  },
  methods: {
    goto(lesson) {
      Music.play();
      this.$f7router.navigate(`/lessons/${lesson}`);
    },
    ...Utils,
  },
}
</script>