// loosely based on https://github.com/IlyaSemenov/vue-observable-persist

import Vue from 'vue';
import { merge } from 'lodash';

// Vue.observable extended to be persisted in localStorage
export default function persistable(initial, key) {
    var access = initial._ ? (data) => data._ : (data) => data;
    merge(access(initial), JSON.parse(localStorage.getItem(key)));
    const watcher = new Vue({
        computed: {
            data: () => Vue.observable(initial),
        },
    });
    watcher.$watch('data', data => {
      localStorage.setItem(key, JSON.stringify(access(data)))
    }, { deep: true });
    return initial;
}
