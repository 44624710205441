var Thoughts = {
  energy: {
    title: 'Low energy thoughts',
    thoughts: [
      `I'm too tired`,
      `It'll take too long`,
      `I'm not in the mood`,
      `It's too cold`,
      `It's too hot`,
      `It's too hard`,
      `It's boring`,
    ],
    feelings: ['apathy', 'resistance', 'fear'],
  },

  deception: {
    title: 'Self deception',
    thoughts: [
      `I'll do it later`,
      `It's just one more day`,
      `It's not that important`,
      `I'll start at a round time`,
      `I have too many other things to do`,
      `There's lots of time to get this done`,
      `I'll just watch one more show`,
    ],
    feelings: ['apathy', 'resistance', 'craving']
  },

  anxiety: {
    title: 'Anxiety thoughts',
    thoughts: [
      'Everybody is looking at me!',
      `I have to stop worrying!`,
      `I hope I don't mess this up`,
      `I will end up homeless`,
      `I have no-one to turn to`,
      `I'm going to be late`,
      `I'm losing control`,
      `I'm drowning`,
      `They hate me`,
    ],
    feelings: ['tension', 'fear', 'shame', 'inadequacy']
  },

  judgement: {
    title: 'Self judgement',
    thoughts: [
      `I'm a loser`,
      `I'm not enough`,
      `I always do this`,
      `I'm not going to make it`,
      `I'm going to fail`,
      `I should do better`,
      `I should have started this already`,
    ],
    feelings: ['apathy', 'shame', 'guilt', 'inadequacy'],
  },
  stress: {
    title: 'Stress',
    thoughts: [
      `I need help!`,
      `I am overwhelmed`,
      `I can't take a break`,
      `I'm crawling the walls`,
      `I'm jittery and upset`,
      `I want to run away`,
      `I want to SCREAM!`,
      `I need sugar/wine/chocolate/anything`,
    ],
    feelings: ['tension', 'craving', 'resistance', 'anger'],
  },
};

export default Thoughts;
