var Lessons = {
  introduction: {
    title: 'Introduction',
    subtitle: 'Master your emotions',
    text: {
      main: `
        Hey there, my name is Eli Finer.

        Welcome to Wuju.

        This app is a form of active meditation that will help you find clarity in times of stress, anxiety or emotional turmoil. But in order to use it under those circumstances you need to practice it a few times first.

        This is why I'd like you to think about something unpleasant in your life. It might be a person you're in conflict with, a judgment you have about yourself or a situation you've been avoiding.

        And you can start by describing the situation briefly in the box below. This will serve as an anchor, the place where you're starting from, a reminder of where you've been.

        Click Next when you're done.
      `,
      secondary: `
        This is the main screen of the app where you will usually start your exploration. You can see a list of feelings, thoughts and physical sensations here. I've highlighted a few common feelings, but you can also take a look through the list to see if there's anything that resonates with you even more.

        When you find something that matches your state of mind, click it, and follow the prompts.
      `,
      summary: `
        Before you go, take a couple of minutes and reflect on how you're feeling. Writing this down in the box below will help you remember to use Wuju the next time you're stressed, anxious or demotivated.

        In fact, it's a good idea to spend a few minutes every night with Wuju, just before bed. If you do this for a few days in a row and listen to additional lessons, you'll see how it can be effective with a whole range of real life situations.

        And please, share Wuju with your friends and family. Lots of people need help these days but very few talk about it. Sharing your struggles and victories can help more than you know.
      `,
    },
    narration: {
      main: 'lessons-introduction-main-2021-03-26.mp3',
      secondary: 'lessons-introduction-secondary-2021-03-26.mp3',
      summary: 'lessons-introduction-summary-2021-03-26.mp3',
    },
    question: `What's on your mind?`,
    feelings: ['tension', 'fear', 'anger', 'shame'],
  },

  stress: {
    title: 'Master your stress',
    subtitle: 'Learn to be calm when facing any challenge',
    text: {
      main: `
        Today we're going to work through some stress. This is going to take about 5 minutes. Why don't you get comfortable, make sure you have your headphones on and close your eyes. Look back through the last few days for a time when you felt particularly stressed. And let's dive in.

        Your head is buzzing. There's so much to do, so many calls to return, so many emails to write. Everyone needs something from you, your boss, your partner, your kids. You've got no time for yourself. You feel like you have no choice but to keep it together and push on. But you also feel your energy dwindling, your will crumbling and the rope tightening around your neck.

        Your mind is looking for someone or something to blame. Maybe you're the kind of person who blames others, or circumstances, or your financial situation. And maybe you're a different kind, and you blame yourself and your shortcomings. It all seems so real, so impossibly real and unavoidable.

        Your chest feels tight, you head is pounding and you think you might get out of this if you only work harder, or faster, or smarter. If you could only do more, or say "No" more often, or find a new productivity hack that would allow you to meet all your obligations.

        But you also know deep inside that's not it. You've tried many hacks before. They work for a while, but something remains fundamentally broken.

        This sense of stress seems like it's caused by external circumstances - you don't have enough money, your job is too hard, your spouse or children are too difficult to deal with. But while these  circumstances definitely trigger the stress, your thoughts and emotions about it are making it worse, way worse. In particular, your stress can cause a kind of rigidity in your thinking that makes it hard to notice creative solutions even if they are staring you right in the face.

        So what are the circumstances that are causing your stress? Describe them in a few sentences and then click Next to explore the thoughts and emotions that arise from them.
      `,
      secondary: `
        Now take a look at the list of feelings on your screen. I've highlighted the ones that are often associated with stress but it might be different for you. And if you find it hard to figure out what you're feeling, just start with Tension.
      `,
      summary: `
        Stress can be very debilitating. It can impair your decision making, limit your creativity and it can even cause physical problems if it persists for a long time.

        Wuju can help you take the edge off, but if you really dive in and explore all the emotions associated with your stress, you can often root it out, at least around a particular situation.

        Spend a couple of minutes reflecting on how you're feeling right now, compared to how you were when you started this. And the next time you're feeling like this, take a break, maybe even go into the bathroom, and spend a few minutes with Wuju.

        It should help.
      `,
    },
    narration: {
      main: 'lessons-stress-main-2021-03-26.mp3',
      secondary: 'lessons-stress-secondary-2021-03-26.mp3',
      summary: 'lessons-stress-summary-2021-03-26.mp3',
    },
    feelings: ['resistance', 'tension', 'fear'],
    question: `What is causing your stress?`,
    placeholder: `
      My kids are driving me nuts
      I'm not going to get that done on time
      My house is a mess
    `
  },

  depression: {
    title: 'Master your motivation',
    subtitle: 'Find creative energy to achieve anything',
    text: `TBD`,
    summary: `TBD`,
    feelings: ['apathy', 'resistance', 'fear'],
    narration: {
      main: 'glass-ting.mp3',
      summary: 'glass-ting.mp3',
    },
    tbd: true,
  },

  inadequacy: {
    title: 'Master your inadequacy',
    subtitle: 'Get rid of your imposter syndrome',
    text: `TBD`,
    summary: `TBD`,
    feelings: ['apathy', 'resistance', 'fear'],
    narration: {
      main: 'glass-ting.mp3',
      summary: 'glass-ting.mp3',
    },
    tbd: true,
  },

  anxiety: {
    title: 'Master your anxiety',
    subtitle: 'Unwind your fear of failure and embarrassment',
    text: `TBD`,
    summary: `TBD`,
    feelings: ['apathy', 'resistance', 'fear'],
    narration: {
      main: 'glass-ting.mp3',
      summary: 'glass-ting.mp3',
    },
    tbd: true,
  },

  addiction: {
    title: 'Master your addictions',
    subtitle: 'Fight your urges and procrastination',
    text: `TBD`,
    summary: `TBD`,
    feelings: ['apathy', 'resistance', 'fear'],
    narration: {
      main: 'glass-ting.mp3',
      summary: 'glass-ting.mp3',
    },
    tbd: true,
  },

  anger: {
    title: 'Master your anger',
    subtitle: 'Transform your most important relationships',
    text: `TBD`,
    summary: `TBD`,
    feelings: ['apathy', 'resistance', 'fear'],
    narration: {
      main: 'glass-ting.mp3',
      summary: 'glass-ting.mp3',
    },
    tbd: true,
  },

  grief: {
    title: 'Master your past',
    subtitle: 'Process regret and grief to find peace and joy',
    text: `TBD`,
    summary: `TBD`,
    feelings: ['apathy', 'resistance', 'fear'],
    narration: {
      main: 'glass-ting.mp3',
      summary: 'glass-ting.mp3',
    },
    tbd: true,
  },
};

Object.keys(Lessons).forEach(key => {
  Lessons[key].key = key;
});

export default Lessons;
