<template>
  <f7-page :page-content="false" name="guide">
    <f7-navbar theme-dark style="height: 40px;">
      <f7-nav-title subtitle="Viewing shared session"></f7-nav-title>
      <f7-nav-right>
        <f7-link @click="stopShare()" icon-ios="f7:xmark" icon-aurora="f7:xmark" icon-md="material:close" color="orange"></f7-link>
      </f7-nav-right>

    </f7-navbar>
    <f7-page-content style="padding-top: 40px;">
      <div id="viewer" ref="viewer">
      </div>
    </f7-page-content>
  </f7-page>
</template>

<style>
  #viewer {
    width: 100%;
    height: 100%;
  }
  .replayer-wrapper {
    width: 100%;
    height: 100%;
    background: url('../static/img/loader.gif');
    background-size: 32px;
    background-position: center;
    background-repeat: no-repeat;
  }
  .replayer-mouse-tail {
    display: none;
  }
  iframe {
    border: none;
  }
</style>

<script>
  import Record from '../js/record.js';
  import * as rrweb from 'rrweb';

  export default {
    data() {
      return {
        ws: null,
      }
    },
    methods: {
      stopShare() {
        this.ws.close();
        this.$f7router.navigate(`/home`);
      }
    },
    mounted() {
      this.ws = Record.connect(this.$f7route.params.group);

      rrweb.Replayer.prototype.handleResize = function () {
        this.iframe.style.display = 'inherit';
        this.iframe.setAttribute('width', '100%');
        this.iframe.setAttribute('height', '100%');
      };
      var replayer = new rrweb.Replayer([], {
        liveMode: true,
        root: this.$refs.viewer,
        mouseTail: false,
        insertStyleRules: [
          '.rr-ignore { display: none }',
          '.view.reduced-height { height: inherit ! important }',
        ],
      });

      this.ws.addEventListener('open', () => {
        Record.refresh(this.ws);
        replayer.startLive();
      });

      this.ws.addEventListener('message', (data) => {
        var event = JSON.parse(data.data);
        replayer.addEvent(event);
      });
    },
}
</script>