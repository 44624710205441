import Vue from 'vue';

export default Vue.observable({
  intensity: {},
  lesson: {
    // store the last lesson and the answer to its question
    key: null,
    answer: null,
  },
  shared: false,
});