<template>
  <f7-page name="settings">
    <wuju-navbar :back="true" :menu="true" title="Settings"></wuju-navbar>
    <f7-list>
      <f7-list-group>
        <f7-list-item title="Configuration" group-title></f7-list-item>
        <f7-list-item title="Narration" footer="Enable or disable narration during lessons and in the release process"><f7-toggle slot="after" :checked="$storage.settings.narration" @change="toggleNarration()"></f7-toggle></f7-list-item>
        <f7-list-item title="Frequent questions" footer="Show or hide the guidance questions during the release process"><f7-toggle slot="after" :checked="$storage.settings.faq" @change="toggleFaq()"></f7-toggle></f7-list-item>
        <f7-list-item title="Breathing animation" footer="Show or hide breathing animation in some questions"><f7-toggle slot="after" :checked="$storage.settings.breathing" @change="toggleBreathing()"></f7-toggle></f7-list-item>
      </f7-list-group>
      <f7-list-group>
        <f7-list-item title="Tools" group-title></f7-list-item>
        <f7-list-item title="Reset data" link="#" @click="reset()"></f7-list-item>
        <f7-list-item title="Update version" link="#" @click="reload()"></f7-list-item>
      </f7-list-group>
    </f7-list>
  </f7-page>
</template>

<script>
import Utils from '../js/utils.js';
import Narration from '../js/narration.js';

export default {
  methods: {
    reset() {
      window.localStorage.clear();
      window.location.href = '/';
    },
    reload() {
      if (window.caches) {
        window.caches.delete('install-cache');
      }
      window.location.href = '/';
    },
    toggleNarration() {
      this.$storage.settings.narration = !this.$storage.settings.narration;
      if (! this.$storage.settings.narration) {
        Narration.interrupt();
      }
    },
    toggleFaq() {
      this.$storage.settings.faq = !this.$storage.settings.faq;
    },
    toggleBreathing() {
      this.$storage.settings.breathing = !this.$storage.settings.breathing;
    },
    ...Utils,
  },
}
</script>