import Email from '../js/email.js';
import Utils from '../js/utils.js';

var SurveyFlow = {
  start: {
    text: `Hey there!<br>This quick survey should take about 2 minutes.<br>Thanks for taking the time!<br>-- Eli`,
    buttons: { 'Next': 'often' },
  },

  often: {
    text: `(1/7) How often do you use Wuju?`,
    control: {
      type: 'pills',
      items: ['every day', 'a few times a week', 'occasionally', 'rarely', 'this is my first time'],
      other: false,
    },
    buttons: { 'Next': 'disappointed' },
  },

  disappointed: {
    text: `(2/7) How would you feel if you couldn't use Wuju anymore?`,
    control: {
      type: 'pills',
      items: ['very disappointed', 'somewhat disappointed', 'not disappointed'],
      other: false,
    },
    buttons: { 'Next': 'help' },
  },

  help: {
    text: `(3/7) What do you use Wuju for?`,
    control: {
      type: 'textarea',
    },
    buttons: { 'Next': 'better' },
  },

  experience: {
    text: `(4/7) What else do you do?`,
    control: {
      type: 'pills',
      items: ['therapy', 'meditation', 'yoga', 'journalling'],
      multiselect: true,
    },
    buttons: { 'Next': 'disappointed' },
  },

  better: {
    text: `(5/7) How can I make Wuju better?`,
    control: {
      type: 'textarea',
    },
    buttons: { 'Next': 'benefit' },
  },

  benefit: {
    text: `(6/7) What kind of people do you think would benefit from Wuju the most?`,
    control: {
      type: 'textarea',
    },
    buttons: { 'Next': 'email' },
  },

  email: {
    text: `(7/7) What's your email?`,
    control: {
      type: 'text',
      placeholder: 'your best email',
    },
    buttons: { 'Next': 'thanks', 'Skip': 'thanks' },
    after: (card, data, action, answers) => {
      console.log(answers);
      var message = Object.keys(answers)
        .filter((key) => answers[key])
        .map((key) => SurveyFlow[key].text + '\n' + answers[key])
        .join('\n');
      Email.send('wuju_template_message', {
        email: answers.email || 'survey@wuju.app',
        subject: 'Wuju survey',
        message: Utils.linebreaks(message + '\n' + JSON.stringify(answers)),
      });
    }
  },
  thanks: {
    text: `Thanks!`,
    buttons: { 'Done': '__exit__' },
  },
}

export default SurveyFlow;