var feelings = {
  // Surface feelings
  apathy: {
    name: 'Apathy',
    // description: 'depressed, numb, bored, exhausted, foggy',
    flavors: ['numbness', 'fogginess', 'boredom', 'exhaustion', 'burnout'],
    // locations: ['head', 'face', 'jaw', 'eyes', 'heart', 'upper belly', 'lower belly'],
    noun: /* Could you feel the */ 'apathy',
    category: 'Surface feelings',
    next: ['resistance', 'anger', 'fear', 'shame'],
    stuck: ['resistance', 'doubt', 'fear'],
    help: {
      start: `
        Apathy is a sense of numbness or fogginess we often associate with depression. When we are in this state of apathy, we don't care about anything, we don't want to do anything, we don't feel anything. Neither happy, nor sad, nor angry - just blank and yet somehow unpleasant. Like a hot pot of soup with the lid on, our emotions are boiling away unseen and unfelt.

        Apathy is useful in dulling the pain but over time it also prevents us from experiencing joy, connection and love.

        Allowing yourself to feel the apathy is a little like listening to silence. It's nothing, and yet it's something. As you listen to it, you may notice other emotions bubbling up - anger, sadness, fear. Be gentle with yourself and only allow as much of it to arise as you can take.
        `,
    },
  },
  tension: {
    name: 'Tension',
    // description: 'stress, overwhelm, rush, tightness',
    locations: ['mind', 'head', 'face', 'jaw', 'eyes', 'neck', 'shoulders', 'chest', 'upper belly', 'lower belly'],
    flavors: ['stress', 'overwhelm', 'rush', 'tightness'],
    noun: /* Could you feel the */ 'tension',
    category: 'Surface feelings',
    next: ['fear', 'doubt', 'peace', 'gratitude'],
    stuck: ['resistance', 'doubt', 'fear'],
    help: {
      start: `
        Tension can range from discomfort to a full on sense of being overwhelmed. It's a signal from our subconscious that we're holding something in. When our minds and bodies are tense, our responses to the outside world are limited, inflexible and ultimately not very effective.

        On top of that, whatever deeper emotion is causing the tension (usually unrecognized fear, anger or shame) is controlling our behavior without our knowledge.

        Tension is easiest to feel in your body - your jaw, your shoulders, your back or your skin. In your mind it may feel like rigidity or a sense of impatience. Allowing yourself to feel how tense you are is the first step to easing the tension.
        `,
    },
    faq: {
      could: {
        question: `If I let go of tension, I'll collapse`,
        answer: `Then by all means, hold on to it!`,
      }
    }
  },
  craving: {
    name: 'Craving',
    // description: 'can\'t stop, can\'t let go, addicted, must have',
    flavors: ['need', 'lack', 'addiction', 'helplessness'],
    locations: ['mouth', 'hands', 'chest', 'upper belly', 'lower belly'],
    noun: /* Could you feel the */ 'craving',
    category: 'Surface feelings',
    next: ['anger', 'shame', 'peace', 'gratitude'],
    stuck: ['resistance', 'doubt', 'fear'],
    extra: {
      question: `What are you craving for?`,
      options: ['love', 'safety', 'intimacy', 'touch', 'distraction', 'food', 'a smoke', 'a drink', 'quiet', 'space'],
      other: 'other',
    },
    help: {
      start: `
        Craving is our response to a sense of lack or emptiness. Superficially we might crave food, a drink or some distraction. Deeper down we might be craving intimacy or a sense of belonging, a sense of completeness, or some peace and quiet.

        Craving is easier to notice after the fact, when we've eaten too much, took one drink too many, or spent another day scrolling through social media. But since we're not actually satisfying our deeper cravings, it will still be there, waiting for you to look at.

        Allowing yourself to feel the craving should help reveal the deeper layers of what you lack and help you seek out what you really need.
      `,
    },
  },
  resistance: {
    name: 'Resistance',
    description: 'stuck, confused, unmoving, can\'t decide',
    locations: ['mind', 'face', 'eyes', 'jaw', 'hands', 'chest', 'upper belly', 'lower belly'],
    noun: /* Could you feel the */ 'resistance',
    category: 'Surface feelings',
    next: ['anger', 'fear', 'sadness'],
    stuck: ['doubt', 'fear'],
    extra: {
      question: `What are you resisting?`,
      options: ['my thoughts', 'my feelings', 'my body', 'my life', 'my past', 'my future', 'everything'],
      other: 'other',
    },
    help: {
      start: `
        Resistance is an internal sense of pushing back against the world without making any progress. You might be resisting a noise, a sight, a person or, more commonly, your own thoughts and emotions.

        It might come up in your mind as "should" or "shouldn't" thoughts. The baby shouldn't be crying. That woman should have apologized. I shouldn't be feeling this way. You can become so consumed with thoughts about how things should be that there's no room to see what, if anything, you can do to change your circumstances.

        Allowing yourself to feel the resistance without trying to change anything is often the first step to opening yourself up to new possibilities.
      `,
    },
  },

  // Core emotions
  fear: {
    name: 'Fear',
    description: 'worry, restlessness, anxiety, panic',
    flavors: ['concern', 'worry', 'restlessness', 'anxiety', 'dread', 'panic'],
    locations: ['face', 'mouth', 'neck', 'arms', 'chest', 'upper belly', 'lower belly', 'upper back', 'lower back', 'knees'],
    noun: /* Could you feel the */ 'fear',
    category: 'Core emotions',
    next: ['anger', 'sadness', 'courage'],
    stuck: ['resistance', 'doubt'],
    extra: {
      question: `What is your fear about?`,
      options: ['letting go', 'the future', 'loneliness', 'embarrassment', 'failure', 'success', 'regret', 'pain', 'death'],
      other: 'other',
    },
    help: {
      start: `
        We tend to think of fear as guiding us away from harm. However, persistent fear can also point at the next step in our growth, towards a more exciting and fulfilling life.

        Allowing yourself to feel the fear, even just naming it as such, is often the first step to breaking free of its grasp and finding the courage to act.

        Some fears have been there for a long time, maybe since childhood. Be gentle with yourself and only touch as much of it as you feel comfortable with. Peeling away even a tiny bit should help.
      `,
    },
    faq: {
      could: {
        question: `If I let go of fear I'll do something stupid`,
        answer: `
          It might seem like if you let go of fear, you'll immediately be forced to do whatever you're afraid of. That's not true. You'll still have your decision making intact and, if anything, more choice.
        `,
      }
    }
  },
  anger: {
    name: 'Anger',
    // description: 'annoyance, frustration, bitterness, disappointment',
    flavors: ['annoyance', 'frustration', 'bitterness', 'disappointment', 'jealousy', 'rage'],
    locations: ['face', 'jaw', 'shoulders', 'hands', 'heart', 'upper belly', 'lower belly'],
    noun: /* Could you feel the */ 'anger',
    category: 'Core emotions',
    next: ['sadness', 'shame', 'gratitude'],
    stuck: ['resistance', 'fear', 'pride'],
    extra: {
      question: `What is your {feeling.noun} about?`,
      options: ['myself', 'other people', 'my life', 'my body', 'my thoughts', 'my emotions', 'everything'],
      other: 'other',
    },
    help: {
      start: `
        We tend to think of anger and violence as being the same thing, but anger only becomes violent if it's repressed and misdirected. Properly applied anger is a tremendous source of strength, clear boundaries and justice.

        When we hold on to anger for a long time, it takes subtler forms like bitterness, frustration or judgement. It can sabotage our relationships and indeed cause us to explode in violence.

        Allowing yourself to feel some of your anger can help you tap into deep reserves of strength and determination and find the best way to express it without blame or judgement.

        Be very gentle with yourself as you explore your anger and make sure you are in a safe space. If you have a lot of it bottled up, it may take a while to work through.
      `,
    },
  },
  sadness: {
    name: 'Sadness',
    // description: 'loneliness, self pity, heartbreak, pain, grief',
    flavors: ['loneliness', 'self pity', 'heartbreak', 'pain', 'grief', 'loss'],
    locations: ['face', 'eyes', 'mouth', 'hands', 'heart', 'upper belly', 'lower belly'],
    noun: /* Could you feel the */ 'sadness',
    category: 'Core emotions',
    next: ['gratitude', 'peace'],
    stuck: ['resistance', 'doubt', 'seriousness'],
    extra: {
      question: `What is your {feeling.noun} about?`,
      options: ['myself', 'my past', 'my future', 'my home', 'my life', 'love', 'death', 'everything'],
      other: 'other',
    },
    help: {
      start: `
        We tend to think sadness is telling us something is wrong with the world. Instead, it often tells us how the world really is and that nothing in it lasts forever. Sadness tends to hide under layers of anger and fear and when fully felt can bring about a sense of love and gratitude for what we do have.

        Sadness may come up as a sense of tightness in your chest, an unexpected bout of loneliness or a subtle hollow or fake feeling when you're otherwise happy.

        Allowing yourself to get in touch with the sadness can help you reconnect with your inner child, heal old wounds and find compassion for those around you. It is a beautiful, gentle emotion, full of wisdom and empathy.

        Allow yourself plenty of time to stay with the sadness especially if you're working with grief or long repressed sense of loss. It's OK to cry and let the tears wash away your pain.
      `,
    },
  },
  shame: {
    name: 'Shame',
    // description: 'embarrassment, humiliation, failure',
    flavors: ['embarrassment', 'humiliation', 'regret', 'failure'],
    locations: ['face', 'ears', 'mouth', 'throat', 'hands', 'heart', 'upper belly', 'lower belly'],
    noun: /* Could you feel the */ 'shame',
    category: 'Core emotions',
    next: ['fear', 'anger', 'sadness'],
    stuck: ['resistance', 'doubt', 'fear'],
    extra: {
      question: `What is your {feeling.noun} about?`,
      options: ['my thoughts', 'my feelings', 'my body', 'my mind', 'my life', 'my past', 'my future', 'everything'],
      other: 'other',
    },
    help: {
      start: `
      We think shame is telling us we're not good enough, that we'll never be good enough, that we need to hide or change ourselves. It often sets in at an early age and stays with us throughout our lives. But shame is also a reflection of our specific combination of gifts and faults, that make us infinitely valuable and unique.

      Shame often comes up as a desire to hide our faces, our bodies and our true feelings; as the sense of being exposed and vulnerable; and sometimes as coldness and hardness as we try to protect our gentle inner core.

      Holding on to shame prevents us from forming healthy bonds, from experiencing closeness and intimacy, and from simply being who we are.

      Shame usually runs deep. Be very gentle with yourself as you allow yourself to feel it, and to remember where it came from. It's OK if you can only allow yourself to feel a tiny bit of it at first.
    `
    },
  },

  guilt: {
    name: 'Guilt',
    // description: 'self-judgement, self-blame, regret',
    flavors: ['self-judgement', 'self-blame', 'regret'],
    locations: ['face', 'ears', 'mouth', 'throat', 'hands', 'heart', 'upper belly', 'lower belly'],
    noun: /* Could you feel the */ 'guilt',
    category: 'Core emotions',
    next: ['fear', 'anger', 'sadness'],
    stuck: ['resistance', 'doubt', 'fear'],
    extra: {
      question: `What is your {feeling.noun} about?`,
      options: ['my actions', 'my thoughts', 'my feelings', 'the past', 'the future', 'everything'],
      other: 'other',
    },
    help: {
      start: `
        We tend to hold on to guilt because of the anger we feel towards ourselves, and believe the guilt to be our due punishment. But while guilt is a useful social construct, repressed guilt can empty our soul, crush our enthusiasm and, importantly, prevent us from making amends.

        Guilt and the fear of guilt often arise together, causing us to both feel awful for what we've done and avoid the people we've done it to in fear of seeing our guilt reflected in their eyes.

        Allowing yourself to feel the guilt and acknowledge the consequences of our actions, can help you move past the self-punishment and towards useful action.
      `,
    },
  },

  // Mindsets
  doubt: {
    name: 'Doubt',
    description: 'skeptical, suspicious, self-doubt',
    noun: /* Could you feel the */ 'doubt',
    category: 'Mindsets',
    stuck: ['resistance', 'fear', 'seriousness'],
    next: ['apathy', 'resistance', 'tension'],
    extra: {
      question: `What is your {feeling.noun} about?`,
      options: ['myself', 'my feelings', 'my thoughts', 'the future', 'this process', 'other people', 'everything'],
      other: 'other',
    },
    help: {
      start: `
        Doubt is a very useful tool that helps us make sense of the world and tell fact from fiction and truth from lies. But when doubt permeates our lives and prevents us from trying out new things, exploring new ideas or believing in people, we end up impoverished and stagnant.

        Surprisingly enough doubt often comes up in our minds as complete certainty: No way this is going to work. This is stupid. I can never trust him again.

        When you allow yourself to feel the doubt, you might notice how much it separates you from the world, from other people and ultimately from yourself.
      `,
      couldnow: `
        You may think that if you let go of doubt, you'll be forced to believe in anything anyone tells you. That's not true. You will still have your ability to question things, but you'll also regain the ability to take calculated risks when appropriate.
      `
    },
  },
  pride: {
    name: 'Pride',
    description: 'arrogant, hurt ego, you\'re wrong, I\'m right',
    noun: /* Could you feel the */ 'pride',
    category: 'Mindsets',
    next: ['sadness', 'gratitude', 'fear', 'anger'],
    stuck: ['resistance', 'doubt', 'fear'],
  },
  inadequacy: {
    name: 'Inadequacy',
    description: 'not good enough, failure, worthless',
    noun: /* Could you feel the */ 'inadequacy',
    category: 'Mindsets',
    next: ['fear', 'sadness', 'courage'],
    stuck: ['resistance', 'doubt', 'fear'],
  },
  scarcity: {
    name: 'Scarcity',
    description: 'sense of lack, not enough, too late, need more',
    noun: /* Could you feel the */ 'scarcity',
    category: 'Mindsets',
    next: ['gratitude'],
    stuck: ['resistance', 'doubt', 'fear'],
  },
  seriousness: {
    name: 'Seriousness',
    description: 'when you let go of gravity, everything seems lighter',
    noun: /* Could you feel the */ 'seriousness',
    category: 'Mindsets',
    next: ['joy', 'gratitude'],
    stuck: ['resistance', 'doubt', 'fear'],
  },

  // Positive emotions
  gratitude: {
    name: 'Gratitude',
    description: 'abundance, trust, acceptance',
    noun: /* Could you feel the */ 'gratitude',
    category: 'Positive emotions',
    flow: 'gratitude',
    stuck: ['resistance', 'doubt', 'fear'],
  },
  courage: {
    name: 'Courage',
    description: 'passionate, excited, determined',
    noun: /* Could you feel the */ 'courage',
    category: 'Positive emotions',
    flow: 'positive',
    stuck: ['resistance', 'doubt', 'fear'],
  },
  joy: {
    name: 'Joy',
    description: 'joyful, happy, cheerful',
    noun: /* Could you feel the */ 'joy',
    category: 'Positive emotions',
    flow: 'positive',
    stuck: ['resistance', 'doubt', 'fear'],
  },
  peace: {
    name: 'Peacefulness',
    description: 'soft, loving, warm, pleasant',
    noun: /* Could you feel the */ 'peacefulness',
    category: 'Positive emotions',
    flow: 'positive',
    stuck: ['resistance', 'doubt', 'fear'],
  },
};

Object.keys(feelings).forEach(key => {
  feelings[key].help = feelings[key].help || {};
  feelings[key].stuck = feelings[key].stuck || ['resistance', 'doubt', 'fear'];
  feelings[key].extra = feelings[key].extra || {};
  feelings[key].key = key;
});

export default feelings;
