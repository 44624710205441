// todo: most of these can probably be replaced by lodash functions

import es6template from 'es6-template-strings';

function groupBy(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

function groupByObjects(objects, groupKey)
{
  return Object.keys(objects).reduce((result, key) => {
    (result[objects[key][groupKey]] = result[objects[key][groupKey]] || {})[key] = objects[key];
    return result;
  }, {});
}

function filterObject(object, predicate)
{
  var result = {};
  return Object.keys(object)
    .filter( key => predicate(object[key], key) )
    .reduce( (result, key) => (result[key] = object[key], result), {} );
}

function clearObject(object) {
  for (var key in object) {
    delete object[key];
  }
}

function capitalize(str) {
  if (! str) return str;
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

function template(str, values) {
  if (! str) return str;
  var functions = {
    split: (str, sep) => str.split(sep || / *\n */),
    filter: (arr) => arr.filter(n => n),
    prefix: (arr, prefix) => arr.map((v) => prefix + v ),
    capitalize: (str) => capitalize(str),
    quote: (arr) => arr.map((v) => `"${v}"` ),
    list: (arr) => arr.join(', '),
    newline: (arr) => arr.join('\n'),
    and: (arr) => englishList(arr, 'and'),
    or: (arr) => englishList(arr, 'or'),
  };
  values = Object.assign(functions, values);
  // allow values to be templates too
  for (var i = 0; i < 3 && str.indexOf('{') != -1; i++) {
    str = str.replace(/{/g, '${');
    str = es6template(str, values);
  }
  return str;
}

function linebreaks(str, cls) {
  return str.trim().replace(/\n+/g, `<br class="${cls}">`);
}

function getPath(obj, path) {
  if (! obj) return null;
  if (! path) return null;
  return path.split('.').reduce((res, key) => res ? res[key] : undefined, obj);
}

function hash(str) {
  return (str + '').split('').reduce((hash, ch) =>
    Math.abs(((hash << 5) - hash) + ch.charCodeAt(0))|0, 0).toString(16);
}

function nth(n) {
  // A. find s[v%10] if >= 20 (20th...99th), B. if not found, try s[v] (0th..3rd), C. if still not found, use s[0] (4th..19th)
  var s = ['th', 'st', 'nd', 'rd'];
  var v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

function englishList(arr, joiner) {
  if (! arr) return arr;
  joiner = joiner || 'and';
  const [last] = arr.slice(-1);
  if (arr.length > 1) {
    return arr.slice(0, -1).join(', ') + ` ${joiner} ` + last;
  } else {
    return last;
  }
}

function choice(arr) {
  return arr[Math.floor(Math.random() * arr.length)];
}

function shorten(str, n) {
  var words = str.split(/\s+/);
  if (words.length > n) {
    return words.slice(0, n).join(' ') + '...';
  } else {
    return str;
  }
}

function trimLines(str) {
  return (str || '').trim().replace(/\n +/g, '\n');
}

function unique(list) {
  return [...new Set(list)];
}

function sortByLength(arr) {
  if (! arr) return arr;
  var copy = [...arr];
  copy.sort((a, b) => a.length - b.length);
  console.log(copy);
  return copy;
}

export default {
  groupBy,
  groupByObjects,
  filterObject,
  clearObject,
  capitalize,
  template,
  linebreaks,
  getPath,
  hash,
  nth,
  englishList,
  choice,
  shorten,
  trimLines,
  unique,
  sortByLength,
};