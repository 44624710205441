<template>
  <div class="share-list">
    <ShareNetwork v-if="show('sms')" network="sms" :url="computedUrl" :title="title" :description="message ? '\n'+message.trim() : ''">
      <div class="share">
        <img src="static/img/sms.png" :width="32">
      </div>
    </ShareNetwork>
    <ShareNetwork v-if="show('whatsapp')" network="whatsapp" :url="computedUrl" :title="title" :description="message ? '\n'+message.trim() : ''">
      <div class="share">
        <img src="static/img/whatsapp.png" :width="32">
      </div>
    </ShareNetwork>
    <ShareNetwork v-if="show('twitter')" network="twitter" url="" :title="message + '\n\n@wujuapp\n'+url">
      <div class="share">
        <img src="static/img/twitter.png" :width="32">
      </div>
    </ShareNetwork>
    <ShareNetwork v-if="show('messenger')" network="messenger" :url="computedUrl" :title="message" :quote="message">
      <div class="share">
        <img src="static/img/facebook-messenger.png" :width="34">
      </div>
    </ShareNetwork>
    <ShareNetwork v-if="show('facebook')" network="facebook" :url="computedUrl" :title="message" :quote="message">
      <div class="share">
        <img src="static/img/facebook.png" :width="32">
      </div>
    </ShareNetwork>
  </div>
</template>

<style scoped>
  .share-list {
    display: block;
    text-align: center;
  }
  .share {
    display: inline-block;
    margin-left: 10px;
  }
</style>

<script>
  import Utils from '../js/utils.js';

  export default {
    props: {
      title: {
        type: String,
        default: 'Wuju'
      },
      labels: {
        type: Boolean,
        default: true,
      },
      url: {
        type: String,
        default: '',
      },
      message: {
        type: String,
        default: '',
      },
      include: {
        type: Array,
        default: null,
      }
    },
    methods: {
      show(app) {
        return ! this.include || this.include.includes(app);
      },
      ...Utils,
    },
    computed: {
      computedUrl() {
        return this.url || window.location.origin;
      },
    }
  }
  </script>