<template>
  <f7-page name="intro">
    <wuju-navbar :menu="false" :back="false" :music="end" title="Wuju" subtitle="be gentle with yourself"></wuju-navbar>
    <f7-swiper ref="swiper" pagination>
      <f7-swiper-slide>
          <div class="splash">
            <br>
            <div class="title">
              <h1 class="center">Master your emotions</h1>
              <h2 class="center">for anxiety, depression and procrastination</h2>
            </div>
            <div class="banner">Wuju</div>
          </div>
      </f7-swiper-slide>
      <f7-swiper-slide>
        <f7-block inset>
          <p>Our natural flow is often blocked by self defeating thoughts:</p>
          <ul>
            <li>I can't do it</li>
            <li>It's not going to work</li>
            <li>I'm too tired</li>
            <li>I'll do it later</li>
          </ul>
        </f7-block>
        <p class="emblem"><f7-icon f7="arrow_2_circlepath_circle" size="80" color="theme"></f7-icon></p>
      </f7-swiper-slide>
      <f7-swiper-slide>
        <f7-block inset>
          <p>These thoughts are typically fueled by layers of underlying emotions:</p>
          <ul>
            <li>Stress</li>
            <li>Anger</li>
            <li>Anxiety</li>
            <li>Guilt</li>
          </ul>
        </f7-block>
        <p class="emblem"><f7-icon f7="layers_alt" size="80" color="theme"></f7-icon></p>
      </f7-swiper-slide>
      <f7-swiper-slide>
        <f7-block inset>
          <p>Wuju will help you identify these thoughts and emotions.</p>
          <p>Then it will help you process them to reveal a calm and focused mind.</p>
        </f7-block>
        <p class="emblem"><f7-icon f7="cloud_sun" size="80" color="theme"></f7-icon></p>
      </f7-swiper-slide>
      <f7-swiper-slide>
        <f7-block inset>
          <p>Wuju is a form of active meditation that combines music and interaction.</p>
          <p>It works best when you have headphones and some time for yourself.</p>
          <f7-list>
            <f7-list-item :title="music ? 'Music enabled' : 'Music disabled'"><f7-toggle slot="after" :checked="music" @change="music = !music"></f7-toggle></f7-list-item>
          </f7-list>
        </f7-block>
        <br>
        <p class="emblem"><f7-icon f7="headphones" size="80" color="theme"></f7-icon></p>
      </f7-swiper-slide>
    </f7-swiper>
    <f7-toolbar position="bottom">
          <f7-link></f7-link>
          <f7-button round filled @click="next()">{{lastSlide() ? `Let's Go` : `Next` }}</f7-button>
          <!-- <f7-button round filled @click="next()">Next</f7-button> -->
          <f7-link></f7-link>
    </f7-toolbar>
  </f7-page>
</template>

<style scoped>
  .splash {
    background-image: url(../static/img/linus-nylund-UCIZh0-OYPw-unsplash.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: -140px;
    width: 100%;
    height: 100%;
  }

  .title {
    color: #666;
    /* border: 1px solid black; */
    background: rgba(255, 255, 255, 0.3);
    border-radius: 10px;;
    padding: 5px 5px;
    margin: 15px;
    line-height: 1.3;
  }

  .banner {
    font-size: 80px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.3);
    text-align: center;
  }

  .swiper-container {
    height: 100%;
  }

  .swiper-slide {
    overflow: hidden;
  }

  .block {
    font-size: 120%;
    height: calc(100% - 60px);
  }

  .scroll {
    overflow-y: scroll;
    margin-top: 0;
    height: 100%;
  }

  .emblem {
    position: absolute;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0.7;
  }

  .thumbnail {
    width: 80px;
    border-radius: 5px;
    float: right;
    margin: 20px 0 20px 20px;
  }

  .fab-right-bottom {
    margin-bottom: 60px;
  }

</style>

<script>
import Music from '../js/music.js';
import Guidance from '../js/guidance.js';
import Utils from '../js/utils.js';
import Email from '../js/email.js';

export default {
  data: function() {
    return {
      slide: 0,
      end: false,
      email: '',
      music: true,
    };
  },
  methods: {
    next() {
      if (this.lastSlide()) {
        this.exit();
      } else {
        this.swiper().slideNext();
      }
    },
    remind() {
      this.$f7.dialog.prompt(
        `Leave your email for a quick reminder to try the app later.`,
        `Reminder email`,
        (email) => {
          if (! email) return;
          Email.send('wuju_template_reminder', { email: email, link: window.location.origin } );
          this.next();
        },
        () => {},
      );
    },
    exit() {
      this.$storage.shown.set('intro');
      if (this.music) Music.play();
      this.$f7router.navigate('/lessons/introduction', { clearPreviousHistory: true, props: { delay: 2000 } });
    },
    swiper() {
      if (! this.$refs.swiper) return;
      return this.$refs.swiper.swiper;
    },
    lastSlide() {
      if (! this.swiper()) return;
      return this.swiper().activeIndex == this.swiper().slides.length - 1;
    },
    ...Utils,
  },
  computed: {
    guidance() {
      return Guidance;
    }
  },
  mounted() {
    // manually listen to events because the F7 Swipe Vue component lacks them
    this.swiper().on('slideChange', () => {
      this.slide = this.swiper().activeIndex;
    });
  }
}
</script>