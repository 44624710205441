import moment from 'moment';
import persistable from './persistable.js';
import Vue from 'vue';
import Feelings from './feelings.js';

export default {
  // General settings
  settings: persistable({
    narration: true,
    faq: true,
    breathing: true,
    tab: 'thoughts'
  }, 'wuju:settings'),

  // Misc data
  misc: persistable({
    progress: 0,
    email: null,
  }, 'wuju:data'),

  // Lessons shown
  lessons: persistable({
    _: {},
    get(name) {
      return this._[name];
    },
    set(name, value) {
      value = value === undefined ? true : value;
      Vue.set(this._, name, value);
    },
    count() {
      return Object.keys(this._).filter((v) => v).length;
    }
  }, 'wuju:lessons'),

  // Narrations played
  narrations: persistable({
    _: {},
    get(name) {
      return this._[name];
    },
    set(name, value) {
      value = value === undefined ? true : value;
      Vue.set(this._, name, value);
    },
  }, 'wuju:narrations'),

  // Other things shown
  shown: persistable({
    _: {},
    get(name) {
      return this._[name];
    },
    set(name, value) {
      value = value === undefined ? true : value;
      Vue.set(this._, name, value);
    },
  }, 'wuju:shown'),

  // Insights database
  insights: persistable({
    _: [],
    get() {
      return this._;
    },
    add(options) {
      var defaults = {
        date: moment().toISOString(),
        text: '',
        feeling: '',
      };
      options = Object.assign({}, defaults, options);
      this._.push(options);
    },
  }, 'wuju:insights'),

  // History database
  history: persistable({
    _: [],
    get() {
      return this._;
    },
    add(options) {
      var defaults = {
        date: moment().toISOString(),
        elapsed: 0,
        reason: '',
        location: '',
        intensity: {
          start: 0,
          end: 0,
        },
      };
      options = Object.assign({}, defaults, options);
      this._.push(options);
      // todo: this should probably be a uuid, not an index
      return history.length - 1;
    },
    update(index, options) {
      this._[index] = options;
      return index;
    },
  }, 'wuju:history'),

  // Persistent pill list options
  pills: persistable({
    _: {},
    get(name, defaults) {
      return this._[name] || defaults || [];
    },
    add(name, item) {
      if (! this._[name]) {
        Vue.set(this._, name, []);
      }
      if (! this._[name].includes(item)) {
        this._[name].push(item);
      }
    },
    set(name, items) {
      Vue.set(this._, name, items)
    },
  }, 'wuju:pills'),

  // Persistent feeling flavors
  flavors: persistable({
    _: {},
    get(feeling) {
      if (! feeling) return [];
      var defaults = Feelings[feeling].flavors;
      return this._[feeling] || defaults || [];
    },
    add(feeling, item) {
      if (! feeling) return;
      item = item.toLowerCase();
      if (! this._[feeling]) {
        Vue.set(this._, feeling, Feelings[feeling].flavors);
      }
      if (! this._[feeling].includes(item)) {
        this._[feeling].unshift(item);
      }
    },
    set(feeling, items) {
      if (! feeling) return;
      Vue.set(this._, feeling, items)
    },
  }, 'wuju:flavors'),

};