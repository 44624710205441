import Vue from 'vue';
import { Dom7 } from 'framework7/framework7.esm.bundle.js';
import Music from './music.js';
import Storage from './storage.js';

const $$ = Dom7;

const Narration = Vue.observable({
  _debug: false,
  _current: -1,
  _queue: [],
  _audio: null,
  _delayTimeout: null,

  init() {
    const _audio = $$('<audio>').append(
      $$('<source>')
        .attr('src', '')
        .attr('type', 'audio/mpeg')
    );
    $$('document').append(_audio);
    _audio.on('ended', () => {
      this.next();
    });
    this._audio = _audio[0];
    this._audio.volume = 0.6;
  },

  play(url, start, once) {
    if (once && Storage.narrations.get(url)) return;
    if (this._debug) console.log('enqueue', url);
    this._queue.push({url, start, once});
    if (this._current == -1) {
      this.next();
    }
    return this;
  },

  interrupt() {
    if (this.playing()) {
      this.stop().ting();
    } else {
      this.stop();
    }
    return this;
  },

  delay(delay) {
    if (this._debug) console.log('enqueue', delay);
    this._queue.push({delay})
    return this;
  },

  exec(func) {
    if (this._debug) console.log('enqueue', func);
    this._queue.push({func});
    return this;
  },

  stop() {
    if (this._debug) console.log('stop');
    this.pause();
    this._current = -1;
    this._queue = [];
    return this;
  },

  next() {
    this._current += 1;
    var item = this._queue[this._current];
    if (item && item.url && item.once) {
      if (this._debug) console.log('playing once', item.url);
      Storage.narrations.set(item.url);
    }
    this._audio.currentTime = 0;
    this.resume();
  },

  resume() {
    var item = this._queue[this._current];
    if (! item) {
      this.stop();
      return;
    };
    if (! Music.active || ! Storage.settings.narration) {
      return;
    }
    if (item.url) {
      if (this._debug) console.log('playAudio', item.url);
      if (this._audio.currentTime == 0) {
        this._audio.src = 'media/narration/' + item.url;
      }
      this._audio.currentTime = item.start || 0;
      this._audio.play();
    } else if (item.delay) {
      if (this._debug) console.log('playDelay', item.delay);
      item.start = Date.now();
      this._delayTimeout = setTimeout(() => {
        this.next();
      }, item.delay);
    } else if (item.func) {
      if (this._debug) console.log('playExec', item.func);
      item.func();
      this.next();
    }
  },

  pause() {
    var item = this._queue[this._current];
    if (! item) return;
    if (item.url) {
      if (this._debug) console.log('pauseAudio', item.url);
      this._audio.pause();
    } else if (item.delay) {
      item.delay = item.delay - Math.max(0, Date.now() - item.start)
      if (this._debug) console.log('pauseDelay', item.delay);
      clearTimeout(this._delayTimeout);
    }
  },

  ting() {
    return this.play('glass-ting.mp3');
  },

  playing() {
    return ! this._audio.ended && ! this._audio.paused && ! this._audio.src.match(/glass-ting.mp3/);
  }
});

export default Narration;
